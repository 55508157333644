<template>
  <div class="p-6">
    <a-form layout="inline" :form="form" class="form-label-4">
      <a-form-item label="日期">
        <a-range-picker
          format="YYYY-MM"
          :value="month"
          :mode="mode"
          @panelChange="panelChange"
          @change="monthChange"
          @openChange="openChange"
        />
      </a-form-item>
      <a-form-item label="项目">
        <a-select-pro
          class="form-control"
          v-decorator="['projectCode']"
          placeholder="请选择"
          allowClear
          showSearch
          :options="projectList"
          @change="doQuery(1)"
          :dropdownMatchSelectWidth="false"
        ></a-select-pro>
      </a-form-item>
      <a-form-item label="班组">
        <a-select-pro
          class="form-control"
          v-decorator="['leaderId']"
          placeholder="请选择"
          allowClear
          showSearch
          :options="leaderList"
          @change="doQuery(1)"
        ></a-select-pro>
      </a-form-item>
      <a-form-item label="类型">
        <a-select-pro
          class="form-control"
          v-decorator="['type']"
          placeholder="请选择"
          allowClear
          showSearch
          :options="BRTypeList"
          @change="doQuery(1)"
        ></a-select-pro>
      </a-form-item>
    </a-form>
    <br />
    <span  style="color: black">
       工人姓名：{{this.userName}}
      &emsp;&emsp;&emsp;
       身份证号：{{this.cardId}}

    </span>
    <br />
    <div class="mt-6">
      <a-table
        rowKey="id"
        bordered
        :loading="loading"
        :columns="tableColumns"
        :dataSource="dataSource"
        :pagination="false"
        size="small"
        :scroll="{
          x: 1590,
        }"
      ></a-table>
      <div class="flex text-lg">
        <div style="width: 80px; text-align: center">汇总</div>
        <div>
          涉及金额/元:
          <span class="text-black">{{ amountSum }}</span>
        </div>
      </div>
      <base-pagination
        class="mt-6"
        :totalCount.sync="totalCount"
        :pageNo.sync="pageNo"
        :pageSize.sync="pageSize"
        @change="pageChange"
        size="small"
      />
    </div>
    <OaDocModal ref="oaDocModal"></OaDocModal>
  </div>
</template>

<script>
import { getProjectListByCard, getLeaderListByCard } from '@/service/getData'
import { queryViewMixin } from '@/utils/mixin'

import ASelectPro from '@/components/SelectPro.vue'
import OaDocModal from './OaDocModal.vue'

export default {
  components: {
    ASelectPro,
    OaDocModal,
  },
  mixins: [queryViewMixin],
  data() {
    const tableColumns = [
      {
        title: '单据完成审批时间',
        width: 80,
        align: 'center',
        dataIndex: 'approveDate',
        fixed: 'left',
      },
      {
        title: '项目',
        width: '12%',
        align: 'left',
        dataIndex: 'projectName',
      },
      {
        title: '班组',
        width: '6%',
        align: 'center',
        dataIndex: 'labourTeamName',
      },
      {
        title: '类型',
        width: '8%',
        align: 'center',
        dataIndex: 'type',
      },
      {
        title: '涉及金额/元',
        width: '6%',
        align: 'center',
        dataIndex: 'amount',
      },
      {
        title: '业务单号',
        width: '8%',
        align: 'center',
        dataIndex: 'businessCode',
      },
      {
        title: '支付单号',
        width: '8%',
        align: 'center',
        dataIndex: 'paymentCode',
      },
      {
        title: '支付账号',
        width: '12%',
        align: 'center',
        dataIndex: 'paymentAccount',
      },
      {
        title: '支付状态',
        width: '6%',
        align: 'center',
        dataIndex: 'paymentState',
      },
      {
        title: '支付日期',
        width: '10%',
        align: 'center',
        dataIndex: 'paymentDate',
      },
      {
        title: '支付流水号',
        width: '8%',
        align: 'center',
        dataIndex: 'paumentNum',
      },
      {
        title: '银行回单号',
        width: '8%',
        align: 'center',
        dataIndex: 'bankReceiptNum',
      },
      {
        title: '操作',
        width: 120,
        align: 'center',
        customRender: (text, row) => {
          return (
            <div>
              <div>
                {row.originCertificate ? (
                  <a
                    onClick={() => {
                      this.checkCert(row)
                    }}
                  >
                    查看原始凭证
                  </a>
                ) : (
                  <span class="text-gray-400">查看原始凭证</span>
                )}
              </div>
              <div>
                {row.receiptFileList.length ? (
                  <a
                    onClick={() => {
                      this.checkReceipt(row)
                    }}
                  >
                    查看收条
                  </a>
                ) : (
                  <span class="text-gray-400">查看收条</span>
                )}
              </div>
              <div>
                {row.promiseBookFileList.length ? (
                  <a
                    onClick={() => {
                      this.checkCommitment(row)
                    }}
                  >
                    查看班组承诺书
                  </a>
                ) : (
                  <span class="text-gray-400">查看班组承诺书</span>
                )}
              </div>
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],

      projectList: [],
      leaderList: [],
      BRTypeList: [],
      month: [],
      mode: ['month', 'month'],
      amountSum: 0,

      cardId:"",
      userName:"",
    }
  },
  methods: {
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        await this.$nextTick()
        const params = {
          ...this.getPageParams(_pageNo, _pageSize),
          cardId: this.$route.query.cardId,
          month: this.month,
        }

        const res = await this.$axios.post(
          '/report/evidence/chain/borrow/list',
          this.formatTimeRangeParams(params, 'month', { format: 'YYYY-MM' }),
        )
        const { pageSize, pageNo, totalCount, list } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
        this.amountSum = res.data.amountSum
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    panelChange(month, mode) {
      this.month = month
      this.mode = [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]]
    },
    monthChange(month) {
      this.month = month
      this.doQuery(1)
    },
    openChange(open) {
      if (!open) {
        this.doQuery(1)
      }
    },
    async openOssUrl(ossUrl) {
      const res = await this.$axios.post('/report/evidence/chain/tmp/pathUrl', {
        ossUrl,
      })
      const url = res.data
      window.open(url)
    },
    checkCert(row) {
      this.openOssUrl(row.originCertificate)
    },
    checkReceipt(row) {
      this.$refs.oaDocModal.start(row.receiptFileList)
    },
    checkCommitment(row) {
      this.$refs.oaDocModal.start(row.promiseBookFileList)
    },
    async getBRTypeList() {
      const res = await this.$axios.post('/report/evidence/chain/borrow/type')
      return res.data
    },
  },
  async mounted() {
    const { cardId, projectCode, leaderId } = this.$route.query
    const param={ cardId, projectCode, leaderId }
    const res = await this.$axios.post(`/report/evidence/chain/worker/Info`, param)
    this.userName=res.data.username
    this.cardId=cardId

    this.projectList = await getProjectListByCard({ cardId })
    this.leaderList = await getLeaderListByCard({ cardId })
    this.BRTypeList = await this.getBRTypeList()
    this.doQuery()
  },
}
</script>

<style lang="less" scoped></style>
